import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import {isTouchDevice} from "./index";
export function linkHover() {
	let allLinks = [
		document.querySelector('#main-header .logo a'), // Might return null
		...document.querySelectorAll('#main-header nav a'), // Always returns a NodeList
		...document.querySelectorAll('a.link') // Always returns a NodeList
	].filter(el => el); // Filters out null values

	allLinks.forEach(link => {

		link.classList.add('fancy-link');

		// Create a span and wrap the link's content
		let span = document.createElement('span');
		span.innerHTML = link.innerHTML;

		// Clear the link and append the new span
		link.innerHTML = '';
		link.appendChild(span);

		// Clone the span and append it
		let clonedSpan = span.cloneNode(true);
		link.appendChild(clonedSpan);

		let span1 = link.querySelector('span:nth-of-type(1)');
		let span2 = link.querySelector('span:nth-of-type(2)');
		new SplitText(span2, {type: "lines"});
		let span2Chars = new SplitText(span2, {type: "chars"});


		let linkTl = gsap.timeline({ paused: true });

		// Define hover animation
		linkTl.to(span1, { yPercent: -100, duration: 0.3, ease: "primary-ease" }, 0)
				.to(span2Chars.chars, { yPercent: -100, duration: 0.3, stagger:0.03, ease: "primary-ease" }, 0);


		// Mouse events
		if (!isTouchDevice) {
			link.addEventListener('mouseenter', () => linkTl.play());
			link.addEventListener('mouseleave', () => linkTl.reverse());
		}


	});





}