import {gsap} from "gsap";

const mm = gsap.matchMedia();
const videoIconTl = gsap.timeline({paused: true, repeat:-1, delay:1, repeatDelay:1});
const arrowDownIconTl = gsap.timeline({paused: true, repeat:-1, delay:1, repeatDelay:1});
const arrowRightIconTl = gsap.timeline({paused: true, repeat:-1, delay:1, repeatDelay:1});

videoIconTl.set('#cursor .video path:nth-of-type(2)', {x: -150})
		.to('#cursor .video path:nth-of-type(1)', {
			transformOrigin: 'center',
			x: 150,
			duration:0.7,
			ease: "primary-ease",
		}, 'start')
		.to('#cursor .video path:nth-of-type(2)', {
			transformOrigin: 'center',
			x: 0,
			duration:0.7,
			delay:0.1,
			ease: "back.out(1.3)",
		}, 'start')
/*		.to('#cursor .video path:nth-of-type(2)', {
			transformOrigin: 'center',
			scale: 1.4,
			duration:0.4,
			ease: "power2.inOut",
			yoyo: true,
			repeat: 1,
		})*/

arrowDownIconTl.set('#cursor .arrow-down path:nth-of-type(2)', {y: -150})
		.to('#cursor .arrow-down path:nth-of-type(1)', {
			transformOrigin: 'center',
			y: 150,
			duration:0.7,
			ease: "primary-ease",
		}, 'start')
		.to('#cursor .arrow-down path:nth-of-type(2)', {
			transformOrigin: 'center',
			y: 0,
			duration:0.7,
			delay:0.1,
			ease: "back.out(1.2)",
		}, 'start')

arrowRightIconTl.set('#cursor .arrow-right path:nth-of-type(2)', {x: -150})
		.to('#cursor .arrow-right path:nth-of-type(1)', {
			transformOrigin: 'center',
			x: 150,
			duration:0.7,
			ease: "primary-ease",
		}, 'start')
		.to('#cursor .arrow-right path:nth-of-type(2)', {
			transformOrigin: 'center',
			x: 0,
			duration:0.7,
			delay:0.1,
			ease: "back.out(1.2)",
		}, 'start')

export function mouseCursor() {
	const cursor = document.getElementById('cursor');
	const cursorInner = cursor.querySelector('div.cursor-inner');

	if (!cursor || !cursorInner) return; // Ensure cursor elements exist before running

	const cursorElements = [...document.querySelectorAll("[data-cursor]")];

	// Setup general cursor follow behavior
	gsap.set(cursor, {xPercent: -50, yPercent: -50});
	gsap.set(cursorInner, {scale: 0});

	let xTo = gsap.quickTo(cursor, "x", {duration: 0.8, delay: 0.2, ease: "elastic.out(0.3)"}),
			yTo = gsap.quickTo(cursor, "y", {duration: 0.8, delay: 0.2, ease: "elastic.out(0.3)"});

	window.addEventListener("mousemove", (e) => {
		xTo(e.clientX);
		yTo(e.clientY);
	});

	// Function to apply cursor effects
	function handleCursorEffect(element, isHovering) {
		const cursorType = element.getAttribute("data-cursor");
		const arrowRight = cursor.querySelector(".arrow-right");
		const arrowDown = cursor.querySelector(".arrow-down");
		const video = cursor.querySelector(".video");

		if (isHovering) {
			element.classList.add('hideCursor');

			if (cursorType === 'arrow-right') {
				arrowRight.classList.add('active');
				arrowDown.classList.remove('active');
				video.classList.remove('active');
				videoIconTl.pause();
				arrowDownIconTl.pause();
				if (!arrowRightIconTl.isActive()) {
					arrowRightIconTl.play(0);
				}
			}
			if (cursorType === 'arrow-down') {
				arrowDown.classList.add('active');
				arrowRight.classList.remove('active');
				video.classList.remove('active');
				videoIconTl.pause();
				arrowRightIconTl.pause();
				if (!arrowDownIconTl.isActive()) {
					arrowDownIconTl.play(0);
				}
			}
			if (cursorType === 'video') {
				video.classList.add('active');
				arrowRight.classList.remove('active');
				arrowDown.classList.remove('active');
				arrowDownIconTl.pause();
				arrowRightIconTl.pause();
				if (!videoIconTl.isActive()) {
					videoIconTl.play(0);
				}
			}

			gsap.to(cursorInner, {scale: 1, rotation: 360, duration: 1.3, ease: "primary-ease"});

		} else {
			element.classList.remove('hideCursor');
			gsap.to(cursorInner, {scale: 0, rotation: '-=360', duration: 0.8, ease: "primary-ease"});
		}
	}

	// Apply hover effects for all elements
	cursorElements.forEach(element => {
		element.addEventListener("mouseenter", () => handleCursorEffect(element, true));
		element.addEventListener("mouseleave", () => handleCursorEffect(element, false));

		if (element.matches(':hover')) {
			handleCursorEffect(element, true);
		}
	});

	// Observer for dynamically added elements
	const observer = new MutationObserver(() => {
		const newElements = [...document.querySelectorAll("[data-cursor]")].filter(el => !cursorElements.includes(el));
		newElements.forEach(element => {
			element.addEventListener("mouseenter", () => handleCursorEffect(element, true));
			element.addEventListener("mouseleave", () => handleCursorEffect(element, false));
		});
		cursorElements.push(...newElements);
	});

	observer.observe(document.body, {childList: true, subtree: true});
}


