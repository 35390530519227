import '../scss/styles.scss';
import {gsap} from "gsap";
import {CustomEase} from "gsap/CustomEase";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

import {linkHover} from "./linkHover";
import {featuredWorkSlides, featuredWorkTitleReveal, revealHomeHero} from "./homePage";
import {homeHeroScroll} from "./homePage";
import {homeIntroText} from "./homePage";
import {mouseCursor} from "./mouseCursor";
import {
	projectHeroScroll,
	projectImagesParallax,
	projectIntroTextReveal, projectTextReveal, projectVideosPlay,
	projectVideosReveal, projectVideosScrollPause,
	revealProjectHero, revealQuote, scrollProgress, statsSection
} from './projectPage';

import Lenis from '@studio-freight/lenis';
import {SplitText} from "gsap/SplitText";
import {allProjectsPageReveal} from "./allProjectsPage";
import {aboutHero, aboutIntroText, clientsReveal, competenciesReveal, linksReveal, quoteReveal} from "./aboutPage";


CustomEase.create("primary-ease", "0.62, 0.01, 0.01, 0.99");
CustomEase.create("primary-ease-out", ".34, 1.56, 0.64, 1");

const siteOverlay = document.getElementById('site-overlay')
const overlay1 = siteOverlay.querySelector('.overlay1');
const overlay2 = siteOverlay.querySelector('.overlay2');

const homeNavLink = document.querySelector('#main-header .logo a');
const projectsNavLink = document.querySelector('#main-header nav a.projects-link');
const aboutNavLink = document.querySelector('#main-header nav a.about-link');

const lenis = new Lenis({
	duration: 1.5,   // Adjust smooth scroll speed
	easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // Custom easing
	smooth: true,    // Enable smooth scrolling
	direction: 'vertical', // Default is vertical
	gestureDirection: 'both', // Allow horizontal gestures
	smoothTouch: false, // Disable smooth scrolling on touch devices
});

export const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;


function raf(time) {
	lenis.raf(time);
	requestAnimationFrame(raf);
}

requestAnimationFrame(raf);

document.addEventListener('DOMContentLoaded', function () {
	const template = document.body.dataset.template;
	revealSite();
	mouseCursor();
	linkHover();
	concealSite();
	if (template === "home") {
		homeNavLink.classList.add('disabled');
		revealHomeHero();
		homeHeroScroll();
		homeIntroText();
		featuredWorkSlides();
		featuredWorkTitleReveal();
	} else if (template === "project") {
		revealProjectHero();
		projectHeroScroll();
		projectImagesParallax();
		projectIntroTextReveal();
		projectVideosReveal();
		projectVideosPlay();
		projectVideosScrollPause();
		projectTextReveal();
		scrollProgress();
		statsSection();
		revealQuote();
	} else if (template === "projects") {
		projectsNavLink.classList.add('disabled');
		projectsNavLink.classList.add('active');
		allProjectsPageReveal();
	} else if (template === "about") {
		aboutNavLink.classList.add('disabled');
		aboutNavLink.classList.add('active');
		aboutIntroText();
		quoteReveal();
		clientsReveal();
		competenciesReveal();
		linksReveal();
		aboutHero();
	}



});

window.addEventListener('load', function () {

});


// click scroll sections site-wide
document.querySelectorAll("[data-click-scroll]").forEach((element) => {

	element.addEventListener("click", (e) => {
		if (!document.documentElement.classList.contains("lenis-scrolling")) {
			e.preventDefault();

			const targetID = element.getAttribute("data-click-scroll");
			const targetElement = document.querySelector(targetID);

			if (targetElement) {
				gsap.to(window, {
					duration: 1.5, // Adjust for smoothness
					ease: 'primary-ease',
					scrollTo: {y: targetElement},
				});
			}
		}
	});

});


function concealSite() {

	document.querySelectorAll('a.local-link').forEach(link => {
		link.addEventListener('click', function (e) {
			e.preventDefault();
			const mouseX = e.clientX;
			const mouseY = e.clientY;
			gsap.set([overlay1, overlay2], {
				clipPath: `circle(0% at ${mouseX}px ${mouseY}px)`
			});
			gsap.to([overlay1, overlay2], {
				clipPath: "circle(150% at " + mouseX + "px " + mouseY + "px)",
				duration: 1,
				ease: "circ.inOut",
				stagger: -0.2,
				onComplete: () => {
					window.location.href = link.href;
				}
			});
		});
	});
}

function revealSite() {
	const template = document.body.dataset.template;
	const siteTitle = document.querySelector('#site-overlay div.overlay1 div');
	const span1 = new SplitText([...document.querySelectorAll('#site-overlay span:nth-of-type(1)')], {type: "chars"});
	new SplitText([...document.querySelectorAll('#site-overlay span:nth-of-type(2)')], {type: "chars"});
	const span2 = new SplitText([...document.querySelectorAll('#site-overlay span:nth-of-type(2)')], {type: "chars"});

	const revealSiteTl = gsap.timeline({
		paused: true, onComplete: () => {
			if ((template !== "home")) {
				document.querySelector("#main-header").classList.add("active")
			}
			siteTitle.style.opacity = '0';
		}
	});

	siteTitle.style.opacity = '1';

	revealSiteTl.set([overlay1, overlay2], {
		clipPath: `circle(150% at 50% 50%)`
	})
			.fromTo(span1.chars, {yPercent: 108},
					{
						yPercent: 0,
						duration: 0.3,
						stagger: 0.03,
						ease: 'back.out(1.4)'
					})
			.fromTo(span2.chars, {xPercent: -108},
					{
						xPercent: 0,
						duration: 0.3,
						stagger: 0.03,
						delay: 0.3,
						ease: 'back.out(1.4)'
					}, '<+0.4')
			.to([overlay1, overlay2], {
				clipPath: "circle(0% at 50% 50%)",
				duration: 0.5,
				stagger: 0.2,
				ease: "circ.out",
				delay: 0.5,
			})

	revealSiteTl.play();
}


