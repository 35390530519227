import {SplitText} from "gsap/SplitText";
import {gsap} from "gsap";


export function aboutHero() {
	const image = document.querySelector('#about-intro .artwork img');

	gsap.to(image, {
		yPercent: 30, // Moves the image up as you scroll
		opacity:0,
		ease: "none",
		scrollTrigger: {
			trigger: '.artwork',
			start: "top top", // Start effect when the image is at the top
			end: "bottom top", // Ends when the image goes out of view
			scrub: 1 // Smooth scrolling effect
		}
	});

}

export function aboutIntroText() {
	const title = document.querySelector('#about-intro h1');
	const titleChars = new SplitText(title, {type: "chars"});
	const introParaSection = document.getElementById('about-intro');
	const para = [...introParaSection.querySelectorAll('.text p')];

	new SplitText(para, {type: "lines", linesClass: "line"});
	new SplitText(para, {type: "words", wordsClass: "word"});

	const words = introParaSection.querySelectorAll('.text p > div > div');

	//h1 reveal
	gsap.fromTo(titleChars.chars, {yPercent: 100}, {
		duration: 0.8,
		yPercent: 0,
		ease: 'primary-ease',
		stagger: 0.03,
		delay: 2,
		onComplete: () => {
			titleChars.revert();
		}
	})

	words.forEach((word) => {
		let wordMask = document.createElement("div");
		wordMask.classList.add('word-mask');
		word.append(wordMask);
	})

	const wordMasks = document.querySelectorAll("#about-intro .word-mask");

	const wordRevealTl = gsap.timeline({
		scrollTrigger: {
			trigger: '#about-intro',
			start: "top top",
			end: "bottom bottom",
			toggleActions: "play none none reverse",
			scrub: true,
		}
	});

	wordMasks.forEach((word) => {
		wordRevealTl.fromTo(word,
				{scaleX: 1},
				{scaleX: 0, duration: 3, ease: "primary-ease"}
		);
	});
}


export function quoteReveal() {
	const quotes = document.querySelectorAll('#quotes li.quote');

	const quoteRevealTl = gsap.timeline({
		scrollTrigger: {
			trigger: '#quotes',
			start: "top 70%",
			end: "top top",
			toggleActions: "play none none reverse",
			ease: 'power3.out' // Ensure this is a valid easing function
		}
	});

// Animate all quotes with a stagger effect
	quoteRevealTl.fromTo(
			quotes, // Pass the array of quote elements directly
			{yPercent: 10, opacity: 0},
			{yPercent: 0, opacity: 1, stagger: 0.2, duration: 1, ease: "power3.out"} // Apply stagger here
	);

}

export function clientsReveal() {
	const title = document.querySelector('#clients h2');
	const titleChars = new SplitText(title, {type: "chars"});
	const list = document.querySelector('#clients p');

	//h2 reveal
	gsap.fromTo(
			titleChars.chars,
			{yPercent: 100},
			{
				duration: 0.8,
				yPercent: 0,
				ease: 'primary-ease', // 'primary-ease' should be a valid ease function
				stagger: 0.03,
				scrollTrigger: {
					trigger: "#clients", // Start animation when #clients enters viewport
					start: "top 80%", // Adjust as needed
					toggleActions: "play none none none" // Play once when in view
				},
				onComplete: () => {
					titleChars.revert();
				}
			}
	);

	gsap.fromTo(
			list,
			{yPercent: 10, opacity:0},
			{
				duration: 0.8,
				yPercent: 0,
				opacity:1,
				ease: 'primary-ease', // 'primary-ease' should be a valid ease function
				stagger: 0.03,
				delay:0.4,
				scrollTrigger: {
					trigger: "#clients", // Start animation when #clients enters viewport
					start: "top 80%", // Adjust as needed
					toggleActions: "play none none none" // Play once when in view
				},
			}
	);

}

export function competenciesReveal() {
	const title = document.querySelector('#competencies h2');
	const titleChars = new SplitText(title, {type: "chars"});
	const list = document.querySelector('#competencies p');

	//h2 reveal
	gsap.fromTo(
			titleChars.chars,
			{yPercent: 100},
			{
				duration: 0.8,
				yPercent: 0,
				ease: 'primary-ease', // 'primary-ease' should be a valid ease function
				stagger: 0.03,
				scrollTrigger: {
					trigger: "#competencies", // Start animation when #clients enters viewport
					start: "top 80%", // Adjust as needed
					toggleActions: "play none none none" // Play once when in view
				},
				onComplete: () => {
					titleChars.revert();
				}
			}
	);

	gsap.fromTo(
			list,
			{yPercent: 10, opacity:0},
			{
				duration: 0.8,
				yPercent: 0,
				opacity:1,
				ease: 'primary-ease', // 'primary-ease' should be a valid ease function
				stagger: 0.03,
				delay:0.4,
				scrollTrigger: {
					trigger: "#competencies", // Start animation when #clients enters viewport
					start: "top 80%", // Adjust as needed
					toggleActions: "play none none none" // Play once when in view
				},
			}
	);
}


export function linksReveal() {
	const title = document.querySelector('#links h2');
	const titleChars = new SplitText(title, {type: "chars"});
	const links = document.querySelectorAll('#links li');

	//h2 reveal
	gsap.fromTo(
			titleChars.chars,
			{yPercent: 100},
			{
				duration: 0.8,
				yPercent: 0,
				ease: 'primary-ease', // 'primary-ease' should be a valid ease function
				stagger: 0.03,
				scrollTrigger: {
					trigger: "#links", // Start animation when #clients enters viewport
					start: "top 80%", // Adjust as needed
					toggleActions: "play none none none" // Play once when in view
				},
				onComplete: () => {
					titleChars.revert();
				}
			}
	);

	gsap.fromTo(
			links,
			{yPercent: 10, opacity: 0},
			{
				yPercent: 0, opacity: 1, stagger: 0.2, duration: 1, ease: "power3.out", scrollTrigger: {
					trigger: "#links", // Start animation when #clients enters viewport
					start: "top 80%", // Adjust as needed
					toggleActions: "play none none none" // Play once when in view
				},
			}
	);

}