import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


let homeHero, imagesContainer, introParaSection, heroH1;


export function revealHomeHero() {

	homeHero = document.getElementById('home-hero3');
	const heroInner = homeHero.querySelector('.inner');
	heroH1 = homeHero.querySelector('h1');
	const h1Clone = heroH1.cloneNode(true);
	heroInner.prepend(h1Clone);
	imagesContainer = homeHero.querySelector('.images');
	const images = homeHero.querySelectorAll(".images img");
	const firstImageClone = images[0].cloneNode(true);
	imagesContainer.appendChild(firstImageClone);
	const homeTitle1 = [...homeHero.querySelectorAll('h1:first-of-type > span:nth-of-type(1)')];
	const homeTitle2 = [...homeHero.querySelectorAll('h1:first-of-type > span:nth-of-type(2)')];
	const homeTitleCharsLine1 = new SplitText(homeTitle1, {type: "chars"});
	const homeTitleCharsLine2 = new SplitText(homeTitle2, {type: "chars"});
	const homeTitle1b = [...homeHero.querySelectorAll('h1:last-of-type > span:nth-of-type(1)')];
	const homeTitle2b = [...homeHero.querySelectorAll('h1:last-of-type > span:nth-of-type(2)')];
	const homeTitleCharsLine1b = new SplitText(homeTitle1b, {type: "chars"});
	const homeTitleCharsLine2b = new SplitText(homeTitle2b, {type: "chars"});
	const allImages = [...homeHero.querySelectorAll(".images img")];

	allImages.forEach((img, index) => {
		//img.style.zIndex = images.length - i; // First image has highest z-index
		if (index !== 0) {
			img.style.clipPath = "circle(0% at center)";
		}
	});

	const revealHomeHeroTl = gsap.timeline({paused:true, delay:1.5});
	revealHomeHeroTl.play();
	revealHomeHeroTl
			.fromTo(imagesContainer, {scale: 1.5}, {
				duration: 1.5,
				scale: 1,
				ease: 'primary-ease'
			})
			.fromTo(homeTitleCharsLine1.chars, {yPercent: 100}, {
				duration: 2,
				yPercent: -100,
				ease: 'primary-ease',
				stagger: 0.03
			}, '<-0.3')
			.from(homeTitleCharsLine1b.chars, {
				duration: 1,
				yPercent: 100,
				ease: 'primary-ease',
				stagger: 0.03
			}, '<0.5')
			.addLabel('textLine2In')
			.fromTo(homeTitleCharsLine2.chars, {yPercent: 100}, {
				duration: 2,
				yPercent: -100,
				ease: 'primary-ease',
				stagger: 0.03
			}, 'textLine2In-=1.5')
			.from(homeTitleCharsLine2b.chars, {
				duration: 1,
				yPercent: 100,
				ease: 'primary-ease',
				stagger: 0.03
			}, 'textLine2In-=1')
			.call(() => {
				animateNextImage()
			})

	function animateNextImage(index = 0) {
		const heroCarouselTl = gsap.timeline();

		heroCarouselTl
				.to(allImages[index + 1], {
					clipPath: 'circle(100% at center)',
					duration: 3,
					ease: 'primary-ease'
				}, 'sameTime')
				.fromTo([allImages[index + 1]], {scale: 0.5}, {scale: 1, duration: 2, ease: 'primary-ease'}, 'sameTime+=0.2')
				.fromTo([allImages[index]], {scale: 1}, {scale: 1.5, duration: 3, ease: 'primary-ease'}, 'sameTime')

		heroCarouselTl.eventCallback("onComplete", () => {
			if (index === allImages.length - 2) {
				allImages.forEach((img, i) => {
					if (i !== 0) {
						img.style.clipPath = "circle(0% at center)";
					}
					img.style.transform = "scale(1)";
				});
				setTimeout(() => {
					animateNextImage(0);
				}, 1500)

			} else {
				setTimeout(() => {
					animateNextImage(index + 1);
				}, 1500)

			}
		});
	}
}

export function homeHeroScroll() {
	const heroH1s = document.querySelectorAll('#home-hero3 h1')

	gsap.to(imagesContainer, {
		yPercent: 40,
		ease: "none",
		scrollTrigger: {
			trigger: homeHero,
			start: "top top",
			end: "bottom top",
			scrub: true,
		}
	});

	gsap.to(heroH1s, {
		filter: 'blur(10px)',
		opacity: 0,
		scale: 0.8,
		ease: "none",
		scrollTrigger: {
			trigger: homeHero,
			start: "top top",
			end: "bottom top",
			scrub: true,
		}
	});

}


export function homeIntroText() {
	introParaSection = document.getElementById('intro-para');
	const para = introParaSection.querySelector('p');

	new SplitText(para, {type: "lines", linesClass: "line"});
	new SplitText(para, {type: "words", wordsClass: "word"});

	const words = introParaSection.querySelectorAll('p > div > div');

	words.forEach((word) => {
		let wordMask = document.createElement("div");
		wordMask.classList.add('word-mask');
		word.append(wordMask);
	})

	const wordMasks = document.querySelectorAll("#intro-para .word-mask");

	const wordRevealTl = gsap.timeline({
		scrollTrigger: {
			trigger: introParaSection,
			start: "top 10%",
			end: "bottom bottom",
			toggleActions: "play none none reverse",
			scrub: true,
		}
	});

	const fadeInParaTl = gsap.timeline({
		scrollTrigger: {
			trigger: introParaSection,
			start: "top 40%",
			end: "top top",
			toggleActions: "play none none reverse",
			scrub: true,
			onLeave: () => document.querySelector("#main-header").classList.add("active"),
			onEnterBack: () => document.querySelector("#main-header").classList.remove("active")
		}
	});

// Loop through each `.word-mask` and animate them **one after another**
	wordMasks.forEach((word) => {
		wordRevealTl.fromTo(word,
				{scaleX: 1},
				{scaleX: 0, duration: 3, ease: "primary-ease"}
		);
	});

	fadeInParaTl.fromTo(para, {opacity: 0}, {opacity: 1, ease: "primary-ease", duration: 0.5})
}

export function featuredWorkSlides() {
	let featuredWorkSection = document.getElementById('featured-work');
	let slides = [...featuredWorkSection.querySelectorAll('a.featured-work-item')];

	slides.forEach((slide, index) => {
		let slideContent = slide.querySelector('.content');
		let slideMedia = slide.querySelector('.media');

		gsap.to(slideMedia, {
			duration: 0.5,
			filter:'blur(6px)',
			ease: "primary-ease",
			scrollTrigger: {
				trigger: slide,
				start: "top top",
				end: "bottom top",
				scrub: true,
			}
		});

		gsap.to(slideContent, {
			yPercent: -30,
			filter:'blur(6px)',
			duration: 0.5,
			opacity:0,
			//stagger:0.2,
			ease: "primary-ease",
			scrollTrigger: {
				trigger: slide,
				start: "top -50%",
				end: "bottom top",
				scrub: true,
			}
		});

		//play / pause videos
		let video = slide.querySelector('video'); // Check if there's a video inside
		if (!video) return; // Skip if no video
		ScrollTrigger.create({
			trigger: slide,
			start: "top 80%", // When the section enters view
			end: "bottom 20%", // When it leaves view
			onEnter: () => {
				video.play().catch(error => console.log("Autoplay prevented:", error));
			},
			onEnterBack: () => {
				video.play().catch(error => console.log("Autoplay prevented:", error));
			},
			onLeave: () => {
				video.pause();
			},
			onLeaveBack: () => {
				video.pause();
			}
		});

	});


}
export function featuredWorkTitleReveal() {
	let title = document.querySelector('#featured-work .wrapper h2');
	const titleChars = new SplitText(title, {type: "chars"});

	gsap.fromTo(titleChars.chars, {yPercent:100}, {
		yPercent:0,
		duration: 0.8,
		ease: "primary-ease",
		stagger: 0.03,
		scrollTrigger: {
			trigger: '#featured-work > .wrapper',
			start: "bottom bottom",
			end: "bottom center",
		}
	});
}