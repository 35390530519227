import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {Draggable} from "gsap/Draggable";
import {DrawSVGPlugin} from "gsap/DrawSVGPlugin";
import {SplitText} from "gsap/SplitText";
import {CustomEase} from "gsap/CustomEase";

gsap.registerPlugin(ScrollTrigger, Draggable, DrawSVGPlugin, SplitText, CustomEase);

let projectHero, projectHeroImage, projectHeroVideo, projectHeroMediaContainer, projectHeroText,
		projectHeroTitle, projectHeroTextBg, projectHeroDetails, heroTitleWords, heroTitleChars, projectImages,
		projectImagesContainer,
		projectImagesSections, projectIntroTextSection, projectIntroTitle, projectIntroText, projectIntroTitleLines,
		projectVideosSections, projectVideo, projectVideoWithTease, projectTextSection;

export function revealProjectHero() {
	projectHero = document.getElementById('project-hero');
	projectHeroImage = projectHero.querySelector('.media img');
	projectHeroVideo = projectHero.querySelector('.media video');
	projectHeroText = projectHero.querySelector('.text');
	projectHeroTitle = projectHeroText.querySelector('h1');
	projectHeroDetails = projectHeroText.querySelectorAll('.details > *');
	projectHeroTextBg = projectHeroText.querySelector('.bg');
	heroTitleWords = new SplitText(projectHeroTitle, {type: "words"})
	heroTitleChars = new SplitText(projectHeroTitle, {type: "chars"});

	const projectHeroMedia = projectHeroImage || projectHeroVideo;

	const projectHeroTl = gsap.timeline({
		paused: true,
		delay: 1,
		onComplete: () => {
			heroTitleChars.revert(), heroTitleWords.revert()
		}
	});

	projectHeroTl
			.fromTo(projectHeroMedia, {scale: 1.4}, {scale: 1, duration: 1.5, delay: 0.3, ease: 'primary-ease'})
			.fromTo(projectHeroTextBg, {clipPath: 'circle(0% at bottom)'},
					{clipPath: 'circle(100% at bottom)', duration: 2, ease: 'primary-ease'}, '<+0.3')
			.fromTo(heroTitleChars.chars, {yPercent: 100}, {
				duration: 0.8,
				yPercent: 0,
				ease: 'primary-ease',
				stagger: 0.03
			}, '<+0.5')

			.fromTo(projectHeroDetails, {filter: 'blur(5px)', opacity: 0, y: 20}, {
				stagger: 0.3,
				filter: 'blur(0px)',
				opacity: 1,
				y: 0,
				duration: 1.5,
				ease: 'primary-ease'
			}, '<+0.5');

	projectHeroTl.play();
}

export function projectHeroScroll() {
	projectHeroMediaContainer = document.querySelectorAll('#project-hero .media')

	const projectHeroImage = projectHero.querySelector('.media img');
	const projectHeroVideo = projectHero.querySelector('.media video');

	const projectHeroMedia = projectHeroImage || projectHeroVideo;

	gsap.fromTo(projectHeroMediaContainer, {yPercent: 0}, {
		yPercent: 20,
		ease: "none",
		scrollTrigger: {
			trigger: projectHero,
			start: "top top",
			end: "bottom top",
			scrub: true,
		}
	});

	gsap.fromTo(projectHeroMedia, {opacity: 1},
			{
				opacity: 0.2,
				ease: "none",
				scrollTrigger: {
					trigger: projectHero,
					start: "top top",
					end: "bottom 50%",
					scrub: true,
					onUpdate: (self) => {
						if (projectHeroMedia === projectHeroVideo) {
							if (self.progress > 0.9) {
								projectHeroVideo.pause(); // Pause when mostly out of view
							} else {
								projectHeroVideo.play(); // Play when coming back into view
							}
						}
					},
					onLeave: () => {
						if (projectHeroMedia === projectHeroVideo) {
							projectHeroVideo.pause(); // Pause when fully out of view
						}
					},
					onEnterBack: () => {
						if (projectHeroMedia === projectHeroVideo) {
							projectHeroVideo.play(); // Play when scrolling back into view
						}
					}
				}
			}
	);
}

export function projectImagesParallax() {
	projectImagesSections = document.querySelectorAll('section.project-image');

	projectImagesSections.forEach(section => {
		projectImagesContainer = section.querySelectorAll('.image');
		projectImages = section.querySelectorAll('.image img');

		gsap.utils.toArray(projectImages).forEach(img => {
			//gsap.set(img, {scale:1.02});

			gsap.fromTo(img,
					{y: "-50%"},  // Start position (move up)
					{
						y: "0%",    // End position (move down)
						scrollTrigger: {
							ease: 'primary-ease',
							trigger: img,
							start: "top bottom",
							end: "bottom 50%",
							scrub: true,
						}
					}
			);
		});

		gsap.utils.toArray(projectImagesContainer).forEach(container => {
			gsap.fromTo(container,
					{scale: "0.8"},
					{
						scale: "1",
						duration: 0.8,
						scrollTrigger: {
							trigger: section,
							start: "top bottom",
							end: "top 10%",
							scrub: true,
						}
					}
			);
		});

	});

}


export function projectVideosReveal() {
	projectVideosSections = document.querySelectorAll('section.project-video');

	projectVideosSections.forEach(section => {
		projectVideo = section.querySelectorAll('video');
		gsap.utils.toArray(projectVideo).forEach(videoMedia => {
			gsap.fromTo(videoMedia,
					{opacity: "0"},
					{
						opacity: "1",
						scrollTrigger: {
							trigger: section,
							start: "top 80%",
							end: "bottom bottom"
						}
					}
			);
		});
	});

}


export function projectVideosPlay() {
	projectVideoWithTease = document.querySelectorAll('section.project-video.has-tease-video');
	projectVideoWithTease.forEach(function (videoSection) {
		let teaser = videoSection.querySelector('video.tease-video');
		let mainVid = videoSection.querySelector('video.main-video');

		teaser.addEventListener('click', () => {
			gsap.to(teaser, {
				onStart() {
					mainVid.muted = !mainVid.muted;
					mainVid.play();
				},
				onComplete() {
					teaser.remove();
					gsap.to('#cursor div.cursor-inner', {scale: 0, rotation: '-=360', duration: 0.8, ease: "primary-ease"});
				},
				duration: 0.3,
				autoAlpha: 0,
				ease: 'primary-ease'
			});
		});
	})
}

export function projectVideosScrollPause() {

	let videos = document.querySelectorAll('section.project-video.has-tease-video video.tease-video');
	let videos2 = document.querySelectorAll('section:not(.has-tease-video).project-video video.main-video');

	let allVideos = [...videos, ...videos2];

	if (allVideos.length > 0) {
		allVideos.forEach(function (video) {
			ScrollTrigger.create({
				start: 0,
				end: "max",
				onUpdate: inViewCheck
			});

			function inViewCheck() {
				if (ScrollTrigger.isInViewport(video, 0.3)) {
					video.play();
				} else {
					video.pause();
				}
			}
		});
	}
}

export function projectIntroTextReveal() {
	projectIntroTextSection = document.getElementById('project-intro-text');
	projectIntroTitle = projectIntroTextSection.querySelector('h2');
	projectIntroText = projectIntroTextSection.querySelector('.text');

	projectIntroTitleLines = new SplitText(projectIntroTitle, {type: "lines"});
	new SplitText(projectIntroTitle, {type: "lines"});


	const projectIntroTextCarouselTl = gsap.timeline();

	projectIntroTextCarouselTl
			.fromTo(
					projectIntroTitleLines.lines,
					{yPercent: 106},
					{
						yPercent: 0,
						stagger: 0.08,
						duration: 0.7,
						ease: 'primary-ease'
					}
			)
			.fromTo(
					projectIntroText,
					{opacity: 0},
					{opacity: 1, duration: 0.8, delay: 0.4}
			)
			.set(projectIntroTitleLines, {onComplete: () => projectIntroTitleLines.revert()});

// Attach ScrollTrigger separately
	ScrollTrigger.create({
		trigger: projectIntroTextSection,
		start: "top 60%",
		end: "top center",
		scrub: false,
		animation: projectIntroTextCarouselTl
	});
}

export function projectTextReveal() {
	projectTextSection = document.querySelectorAll('section.project-text');

	projectTextSection.forEach(function (textSection) {
		let paragraphs = textSection.querySelectorAll('p');
		gsap.fromTo(paragraphs, {opacity: 0, yPercent: 10}, {
			opacity: 1,
			yPercent: 0,
			duration: 1,
			ease: "power2.inOut",
			stagger: 0.1,
			scrollTrigger: {
				trigger: textSection,
				start: "top 70%",
			}
		});
	})
}

export function scrollProgress() {
	gsap.to("#progress-bar", {
		scaleX: 1, // Expands fully
		ease: "none", // Ensures smooth movement
		scrollTrigger: {
			trigger: "body",
			start: "top top",
			end: "bottom bottom",
			scrub: 0.5, // Smooth progress tracking
		}
	});
}

export function statsSection() {
	const statsSection = document.querySelector("#stats");

	if (statsSection) {
		const statsItems = document.querySelectorAll("#stats .stat");
		const title = document.querySelector("#stats h2");

		gsap.from(title, {
			opacity: 0,
			x: 20,
			duration: 0.6,
			stagger: 0.05,
			ease: "primary-ease",
			scrollTrigger: {
				trigger: statsSection,
				start: "top 80%",
				end: "top 20%",

			}
		});


		gsap.utils.toArray(statsItems).forEach((item, index) => {
			const numberElement = item.querySelector("span.number");
			const textElement = item.querySelector("p");

			const splitText = new SplitText(numberElement, {type: "chars"});

			gsap.from(splitText.chars, {
				opacity: 0,
				x: 20,
				duration: 0.6,
				stagger: 0.05,
				ease: "primary-ease",
				scrollTrigger: {
					trigger: item,
					start: "bottom bottom",
					end: "bottom 50%",
					scrub: false,
				}
			});

			gsap.from(textElement, {
				opacity: 0,
				y: 20,
				duration: 0.3,
				stagger: 0.05,
				ease: "primary-ease",
				scrollTrigger: {
					trigger: item,
					start: "bottom bottom",
					end: "bottom 50%",
					scrub: false,
				}
			});
		});
	}

}

export function revealQuote() {

	if (document.querySelector("#project-quote")) {
		const bg = document.querySelector("#project-quote .bg");
		const text = document.querySelectorAll("#project-quote .wrapper p");

		gsap.fromTo(bg, {clipPath: 'circle(0% at 0% 0%)'},{
			clipPath: 'circle(200% at 0% 0%)',
			duration: 1,
			ease: "primary-ease",
			scrollTrigger: {
				trigger: "#project-quote",
				start: "top 50%",
				toggleActions: "play none none none",
			},
		});

		gsap.from(text, {
			opacity: 0,
			duration: 1,
			stagger:0.2,
			ease: "primary-ease",
			scrollTrigger: {
				trigger: "#project-quote",
				start: "top 50%",
				toggleActions: "play none none none",
			},
		});
	}
}