import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";

export function allProjectsPageReveal() {
	const allProjectsPageRevealTl = gsap.timeline({paused: true, delay: 1.5});
	const title = document.querySelector('#project-title h1');
	const titleChars = new SplitText(title, {type: "chars"});
	const projectsList = [...document.querySelectorAll('#works-list li')]

	allProjectsPageRevealTl.fromTo(titleChars.chars, {yPercent: 100}, {
		duration: 0.8,
		yPercent: 0,
		ease: 'primary-ease',
		stagger: 0.03
	})
			.fromTo(projectsList, {yPercent: 20, opacity: 0}, {
				yPercent: 0,
				opacity: 1,
				stagger:0.1,
				ease: 'primary-ease',
			},'<0.5')

	allProjectsPageRevealTl.play();

}